<i18n src="@/locales/useful_dental_tourism.json" />
<i18n src="@/locales/articles-card-content.json" />
<i18n src="@/locales/card-content-useful-tourism.json" />

<template>
  <div class="dental-tourism">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title_1')"
      />
    </div>
    <b-message>
      <h2 class="is-size-5 mb-2">
        {{ $t('h2_title_1') }}
      </h2>
      <p>
        {{ $t('article_p1_1') }}
      </p>
      <div v-html="$t('article_p1_2')" />
      <p class="mt-1">
        <span class="icon-text">
          <span class="icon is-info">
            <i class="mdi mdi-arrow-right-thick" />
          </span>
          <a
            href="/contact"
            class="is-info"
          >{{ $t('more_info_1') }}</a></span>
      </p>
    </b-message>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section class="section">
      <ImanArticle>
        <p>
          {{ $t('article_p2_1') }}
        </p>
        <div v-html="$t('article_p2_2')" />
        <b-message
          type="is-info"
        >
          <p>
            {{ $t('message_1') }}
          </p>
        </b-message>
      </ImanArticle>
    </section>
    <ImanH2>{{ $t('h2_title_3') }}</ImanH2>
    <section class="pt-5 pb-5 fixed-background">
      <div class="columns is-multiline">
        <div
          v-for="contentUsefulTou in contentUsefulTourism"
          :key="contentUsefulTou.id"
          class="column is-3-desktop-only is-4"
        >
          <ImanPictureBox
            :content="contentUsefulTou"
          />
        </div>
      </div>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";
  export default {
    name: 'UsefulDentalTourism',
    components: {
      ImanArticleSection
    },
    mixins: [
      articlesCardContentFilterMixin,
      metaMixin
    ],
    data () {
      return {
        articlesCardContentId: [52, 61, 88, 2242]
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../assets/img/thumbnail/MINIATURE_5.3.png')
      },
      contentUsefulTourism () {
        switch (this.$i18n.locale) {
          case 'en':
            return Object.values(this.$i18n.messages.en.contentUsefulTourism)
              .filter(usefulTourismContent => usefulTourismContent)
          case 'ru':
            return Object.values(this.$i18n.messages.ru.contentUsefulTourism)
              .filter(usefulTourismContent => usefulTourismContent)
          default:
            return Object.values(this.$i18n.messages.fr.contentUsefulTourism)
              .filter(usefulTourismContent => usefulTourismContent)
        }
      }

    }
  }
</script>

<style lang="scss" scoped>
.header-background {
  @media only screen and (min-width: 768px) {
    /* tablet et ordinateurs */
    background-image: url("../../../assets/img/useful/useful-dental-care/H1-tourisme-dentaire.jpg");
  }
  @media only screen and (max-width: 767px) {
    /* smartphone en mode paysage */
    background-image: url("../../../assets/img/useful/useful-dental-care/H1-tourisme-dentaire.jpg");
  }
  @media only screen and (max-width: 767px) and (orientation: portrait) {
    /* smartphone en mode portait */
    background-image: url("../../../assets/img/useful/useful-dental-care/H1-mobile-tourisme-dentaire.jpg");
  }
}
</style>
